import React from 'react';
import './PictureGallery.css';

interface PictureGalleryProps {
    images: string[]; // Array of image URLs
}

const PictureGallery: React.FC<PictureGalleryProps> = ({ images }) => {

    // Download individual image
    const downloadImage = (src: string) => {
        const link = document.createElement('a');
        link.href = src;
        link.download = src.split('/').pop() || 'downloaded-image'; // Extract filename from URL or use a default name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up the created link
    };

    // Print individual image
    const printImage = (src: string) => {
        const printWindow = window.open('', '_blank');
        if (printWindow) {
            printWindow.document.write(`
                <html>
                    <head>
                        <title>Print Image</title>
                        <style>
                            body, html { margin: 0; padding: 0; display: flex; align-items: center; justify-content: center; height: 100%; }
                            img { max-width: 100%; max-height: 100vh; }
                        </style>
                    </head>
                    <body>
                        <img src="${src}" alt="Printable Image" onload="window.print(); window.close();" />
                    </body>
                </html>
            `);
            printWindow.document.close();
        } else {
            alert("Pop-up blocked! Please allow pop-ups for this website to print images.");
        }
    };

    return (
        <section className="container py-5">
            <h2 className="text-center mb-4 ">Color Book Gallery</h2>
            <div className="row g-4">
                {images.map((src, index) => (
                    <div className="col-md-4" key={index}>
                        <div className="card shadow-sm border-0 image-card">
                            <img src={src} className="card-img-top rounded" alt={`Gallery item ${index}`} />
                            <div className="overlay d-flex flex-column align-items-center justify-content-center">
                                {/*<button className="btn btn-warning mb-2" onClick={(e) => {*/}
                                {/*    e.stopPropagation();*/}
                                {/*    downloadImage(src);*/}
                                {/*}}>*/}
                                {/*    <i className="bi bi-download"></i> Download*/}
                                {/*</button>*/}
                                <button className="btn btn-warning" onClick={(e) => {
                                    e.stopPropagation();
                                    printImage(src);
                                }}>
                                    <i className="bi bi-printer"></i> Print
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default PictureGallery;

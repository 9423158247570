import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import Login from './pages/Login';
import Register from './pages/Register';
import ResetPassword from './pages/ResetPassword';
import BuyCredits from './pages/BuyCredits';
import GalleryPage from "./pages/GalleryPage";
import Navbar from "./components/Navbar";
import ContactPage from "./pages/ContactPage";
import UserPage from "./pages/UserPage";
import {AuthProvider} from "./utils/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import OAuthRedirect from "./pages/OAuthRedirect";
import LandingPage from "./pages/LandingPage";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || 'your-publishable-key');

const App: React.FC = () => {

    return (


        <Elements stripe={stripePromise}>
            {/* Navbar at the top of the page */}

                <AuthProvider>
                    <Router>
                        <Navbar/>
                        <Routes>

                            <Route path="/login" element={<Login/>}/>
                            <Route path="/oauth2/redirect" element={<OAuthRedirect/>}/>
                            <Route path="/register" element={<Register/>}/>
                            <Route path="/reset-password" element={<ResetPassword/>}/>
                            <Route path="/" element={<LandingPage/>}/>
                            <Route element={<ProtectedRoute />}>
                                <Route path="/dashboard" element={<GalleryPage/>}/>
                                <Route path="/buy-credits" element={<BuyCredits/>}/>
                                <Route path="/contact" element={<ContactPage/>}/>
                                <Route path="/user" element={<UserPage/>}/>
                            </Route>
                        </Routes>
                    </Router>
                </AuthProvider>
        </Elements>


    );
};

export default App;
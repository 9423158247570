import React, { useState } from 'react';
import GoogleAuthButton from '../components/GoogleAuthButton';
import { CredentialResponse } from '@react-oauth/google';
import { Link, useNavigate } from 'react-router-dom';
import { setToken } from "../utils/auth";
import './Login.css';

const TOKEN_KEY = process.env.TOKEN_KEY;

const Login: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleGoogleSuccess = (response: CredentialResponse) => {
        console.log('Google Login Successful:', response);
    };

    const handleGoogleError = () => {
        console.error('Google Login Failed');
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const response = await fetch('/api/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Login failed');
            }

            const data = await response.json();
            setToken(data.token);
            navigate('/dashboard');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="login-container d-flex flex-column align-items-center justify-content-center min-vh-100">
            <div className="card login-card shadow">
                <h2 className="text-center login-title">Login</h2>

                <div className="mb-3 text-center">
                    <GoogleAuthButton />
                </div>

                {error && <div className="alert alert-danger text-center">{error}</div>}

                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                            Email:
                        </label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control"
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">
                            Password:
                        </label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="form-control"
                            required
                        />
                    </div>

                    <button
                        type="submit"
                        className="btn btn-primary w-100 login-btn"
                        disabled={loading}
                    >
                        {loading ? 'Logging in...' : 'Login'}
                    </button>
                </form>

                <div className="text-center mt-3">
                    <p className="login-text">
                        Don’t have an account? <Link to="/register" className="sign-up-link">Sign up</Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Login;
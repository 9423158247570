import {jwtDecode} from "jwt-decode";


const TOKEN_KEY = process.env.TOKEN_KEY || '';

interface DecodedToken {
    exp: number;
    [key: string]: any;
}

export const setToken = (token: string) => {
    localStorage.setItem(TOKEN_KEY, token);
};

export const getToken = (): string | null => {
    return localStorage.getItem(TOKEN_KEY);
};

export const clearToken = () => {
    localStorage.removeItem(process.env.TOKEN_KEY || '');
};

export const isTokenExpired = (token: string | null): boolean => {
    if (token==null){
        return true;
    }
    try {
        const decoded = jwtDecode<DecodedToken>(token);
        return decoded.exp < Date.now() / 1000;
    } catch (error) {
        return true; // Treat as expired if token is invalid
    }
};
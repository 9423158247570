import React from 'react';
import googleLogo from '../assets/google-logo.png';

const GoogleAuthButton: React.FC = () => {
    const onClick = () => {
        window.location = `${process.env.REACT_APP_API_URL!}/api/auth/google` as unknown as Location;
    };

    return (
        <div className="d-flex justify-content-center">
            <button
                className="btn btn-outline-primary d-flex align-items-center"
                style={{
                    fontSize: '1rem',
                    height: '40px',
                    padding: '0.5rem 1rem',
                }}
                onClick={onClick}
            >
                <img
                    src={googleLogo}
                    alt="Google"
                    style={{
                        height: '24px',
                        marginRight: '10px',
                    }}
                />
                Sign in with Google
            </button>
        </div>
    );
};

export default GoogleAuthButton;
import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {setToken} from "../utils/auth";
const TOKEN_KEY = process.env.TOKEN_KEY
const OAuthRedirect: React.FC = () => {
    const navigate = useNavigate();
    const handleGoogleCallback = async () => {
        try {
            const urlParams = new URLSearchParams(window.location.search)
            const token = urlParams.get('token')

            if (token) {
                setToken(token)
                navigate('/dashboard');
            } else {
                console.error('No token received');
            }
        } catch (error) {
            console.error('Error during Google authentication:', error);
        }
    };

    useEffect(() => {
        handleGoogleCallback();
    }, []);

    return (
        <div style={{ maxWidth: '400px', margin: '0 auto', padding: '2rem' }}>

        </div>
    );
};

export default OAuthRedirect;
import React, {useEffect, useRef, useState} from 'react';
import PictureGallery from '../components/PictureGallery';
import {fetchWithAuth} from '../utils/fetchClient';
import './GalleryPage.css';

const sampleImages = [
    'public/images/family.webp',
    'public/images/baby.webp',
    'public/images/bird.webp',
    'public/images/bird_2.webp',
    'public/images/cat.webp',
    'public/images/bird_3.webp',
];

const GalleryPage: React.FC = () => {
    const [images, setImages] = useState(sampleImages);
    const [user, setUser] = useState<any | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [prompt, setPrompt] = useState('');
    const [generatedResponse, setGeneratedResponse] = useState<string | null>(null);
    const [isDragging, setIsDragging] = useState(false);

    const isMounted = useRef(false);

    const fetchUserData = async () => {
        try {
            const response = await fetchWithAuth('/api/user/me', {
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                requiresAuth: true,
            });
            if (!response.ok) throw new Error('Failed to fetch user data');
            const data = await response.json();
            setUser(data);
            const images = data?.generatedImages.map((image: any) => image.url);
            setImages((prevImages) => [...images, ...prevImages]);
            setError(null);
        } catch (err) {
            setError((err as Error).message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isMounted.current) return;
        fetchUserData();
        isMounted.current = true;
    }, []);

    const handleFiles = (files: FileList) => {
        Array.from(files).forEach((file) => uploadImage(file));
    };

    const uploadImage = async (file: File) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetchWithAuth('/api/upload', {
                method: 'POST',
                body: formData,
                requiresAuth: true,
            });
            if (!response.ok) throw new Error('Failed to upload image');
            const data = await response.json();
            setImages((prevImages) => [data.imageUrl, ...prevImages]);
        } catch (error) {
            console.error('Error uploading image:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(false);
        if (event.dataTransfer.files) handleFiles(event.dataTransfer.files);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => setIsDragging(false);

    const handlePromptSubmit = async () => {
        if (!prompt || prompt.length <= 0) {
            return;
        }
        setLoading(true);
        try {
            const response = await fetchWithAuth('/api/generate-image', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({prompt}),
                requiresAuth: true,
            });
            const data = await response.json();
            setImages((prevImages) => [data.imageUrl, ...prevImages]);
        } catch (error) {
            console.error('Error generating image:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className="container py-5">
            <h2 className="text-center mb-4">Dashboard</h2>
            {/* User Information */}
            {user && (
                <div className="user-info-card mb-4 p-3 rounded shadow-sm text-center">
                    <h5>My Information</h5>
                    <p><strong>Email:</strong> {user.email}</p>
                    <p><strong>Credits:</strong> {user.remainingAmountOfCredits}</p>
                </div>
            )}

            {/* Loading and Error Messages */}
            {loading && <p className="text-center">Loading data...</p>}
            {error && <p className="alert alert-danger text-center">{error}</p>}

            {/* Prompt Section */}
            <div className="mb-5 input-generation-container">
                <h5>Generate Coloring Book Based on Description</h5>
                <div className="input-group">
                    <input
                        type="text"
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        className="form-control"
                        placeholder="Luxembourg during winter time"
                        disabled={!user || user.remainingAmountOfCredits <= 0}
                    />
                    <button
                        onClick={user && user.remainingAmountOfCredits > 0 ? handlePromptSubmit : () => window.location.href = '/buy-credits'}
                        className="btn btn-primary"
                    >
                        {user && user.remainingAmountOfCredits > 0 ? 'Generate Color Book' : 'Buy Credits'}
                    </button>
                </div>
                {loading && (
                    <div className="text-center mt-3">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Generating...</span>
                        </div>
                    </div>
                )}
                {generatedResponse && (
                    <div className="mt-3 alert alert-info">
                        <strong>Generated Response:</strong> {generatedResponse}
                    </div>
                )}
            </div>
            {/* Drag-and-Drop Upload Section */}
            {user && user.remainingAmountOfCredits > 0 && (
                <div
                    className={`drag-drop-area mb-4 p-4 rounded text-center ${isDragging ? 'dragging' : ''}`}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onClick={() => document.getElementById('file-input')?.click()}
                >
                    <div className="upload-icon">
                        <i className="fas fa-upload"></i>
                    </div>
                    <h5>Drag & Drop to Upload or Click to Select a File</h5>
                    <input
                        id="file-input"
                        type="file"
                        onChange={(e) => e.target.files && handleFiles(e.target.files)}
                        className="form-control mt-2"
                        multiple
                    />
                </div>
            )}
            {/* Picture Gallery */}
            <PictureGallery images={images}/>
        </section>
    );
};

export default GalleryPage;
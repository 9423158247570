import React, { useState } from 'react';
import './FAQ.css'; // Optional for styling if needed

interface FAQItem {
    question: string;
    answer: string;
}

const faqData: FAQItem[] = [
    { question: "What’s included in the packages?", answer: "Here's what you can expect from each of our packages:\n" +
            "</br><b>50 Coloring Pages</b> \n" +
            "</br> 50 custom-designed coloring pages with 6 additional generated pages available in your dashboard.\n" +
            "</br><b>150 Coloring Pages</b>\n" +
            "</br>150 custom-designed coloring pages with 6 additional generated pages available in your dashboard.\n" +
            "</br><b>250 Coloring Pages</b>\n" +
            "</br>250 custom-designed coloring pages with 6 generated pages available in your dashboard.\n" +
            "\n" +
            "</br>" +
            "</br>Please note that we do not offer free generations. Once you press the \"Generate\" button, credits are used and are non-refundable."},
    { question: "How to get the best generation results?", answer: "To achieve the best generation results, be as descriptive in your wish as possible. Providing detailed descriptions helps ensure that the generated output matches your expectations more accurately. Describe colors, themes, elements, and any specific details you want to see. The more information you provide, the better the system can understand and create content tailored to your vision." },
    { question: "Why does it cost?", answer: "Generating a coloring page through Coloriage.lu involve a cost for several key reasons:\n" +
            "\n" +
            "<br/> 1. AI Model Usage: The process relies on sophisticated AI technology that requires computational resources, including processing power and memory, to generate high-quality and customized images.\n" +
            "<br/> 2. Custom Designs and Requests: Creating unique and specific coloring pages, especially when personalized, involves detailed processing and tailoring, which demands additional resources and effort.\n" +
            "<br/> 3. Development and Maintenance Costs: Maintaining and improving the AI model involves ongoing research, development, and updates to ensure it performs well, remains accurate, and continues to generate creative results.\n" +
            "<br/> 4. Quality Assurance: To ensure that generated coloring pages meet a high standard of quality, there are validation, testing, and refinement processes that contribute to the overall cost.\n" +
            "<br/> 5. Support and Infrastructure: The backend systems, servers, security protocols, and support services required to deliver results smoothly add to operational costs.\n" },
    { question: "What license do I get for the coloring pages?", answer: "When you generate coloring book pages using  Coloriage.lu, the licensing typically follows OpenAI's terms of use, which generally state:\n" +
            "\n" +
            "<br/> 1. User Rights: As a user, you own the content you create using LetzCode's tools, including images, text, and other media generated from prompts you provide. This means you can use, share, distribute, and monetize the generated content.\n" +
            "<br/> 2. Commercial Use: LetzCode grants users the right to use content generated by its tools for commercial purposes. You may sell, modify, or incorporate the generated content, such as coloring book pages, into products without any royalty obligations to LetzCode. \n" +
            "<br/> 3. Compliance with Terms: Your usage of generated content must adhere to LetzCode's usage policies and terms of service. These policies generally restrict illegal activities, hate speech, and other harmful or inappropriate content.\n" +
            "<br/> 4. No Attribution Requirement: Typically, there is no requirement to attribute LetzCode when using generated content unless stated otherwise in specific terms of service or product-specific agreements.\n" +
            "<br/> 5. Limitations on LetzCode Content: While you retain broad rights over your generated content, you cannot claim ownership of the underlying AI model or represent the LetzCode technology itself as your own. Redistributing the model or platform as your own product is prohibited.\n" }
];

const FAQ: React.FC = () => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const toggleFAQ = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="faq-container">
            {faqData.map((item, index) => (
                <div key={index} className="faq-item">
                    <div
                        className="faq-question"
                        onClick={() => toggleFAQ(index)}
                    >
                        <span>{item.question}</span>
                        <span className={`chevron ${activeIndex === index ? 'open' : ''}`}>&#9660;</span>
                    </div>
                    {activeIndex === index && (
                        <div className="faq-answer" dangerouslySetInnerHTML={{__html: item.answer}}></div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default FAQ;
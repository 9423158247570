import React, { createContext, useContext, useEffect, useState } from 'react';
import { setToken, clearToken, getToken, isTokenExpired } from './auth';

interface AuthContextType {
    isAuthenticated: boolean;
    login: (token: string) => void;
    logout: () => void;
}

interface AuthProviderProps {
    children: React.ReactNode; // Define children prop here
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!getToken());

    useEffect(() => {
        const token = getToken();
        if (token && isTokenExpired(token)) {
            logout();
        }
    }, []);

    const login = (token: string) => {
        setToken(token);
        setIsAuthenticated(true);
    };

    const logout = () => {
        clearToken();
        setIsAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
import React, {useState} from 'react';

const ContactPage: React.FC = () =>   {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });
    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({...prevData, [name]: value}));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Mock submission (In a real app, send formData to backend here)
        console.log('Form submitted:', formData);
        setSubmitted(true);
        setFormData({name: '', email: '', subject: '', message: ''});
    };
    return (
        <section className="container py-5">
            <h2 className="text-center mb-4" >Contact Us</h2>
            <p className="text-center mb-5">We’d love to hear from you! Please fill out the form below, and we’ll get in
                touch as soon as possible.</p>

            <div className="row justify-content-center">
                <div className="col-md-8">
                    {submitted && (
                        <div className="alert alert-success text-center" role="alert">
                            Thank you! Your message has been sent successfully.
                        </div>
                    )}

                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="form-control"
                                required
                            />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="form-control"
                                required
                            />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="subject" className="form-label">Subject</label>
                            <input
                                type="text"
                                id="subject"
                                name="subject"
                                value={formData.subject}
                                onChange={handleChange}
                                className="form-control"
                            />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="message" className="form-label">Message</label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                className="form-control"
                                rows={5}
                                required
                            ></textarea>
                        </div>

                        <button type="submit" className="btn btn-primary w-100 fw-bold">
                            Send Message
                        </button>
                    </form>
                </div>
            </div>
        </section>
    )
};

export default ContactPage;

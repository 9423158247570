import React from 'react';
import './LandingPage.css';
import logo from '../assets/hero.png';
import iconWhyUs1 from '../assets/book1.png';
import iconWhyUs2 from '../assets/data seurity2.png';
import iconWhyUs3 from '../assets/Child.png';
import iconStep1 from '../assets/60edbb4b466b1a4b8b955bb0be8dfd85.png';
import iconStep2 from '../assets/ebce1a8105b24d24621a654a8433bbc1.png';
import iconStep3 from '../assets/5a839a16ca56b37823c774a0fe3387fa.png';
import {getToken, isTokenExpired} from "../utils/auth";

const whyUsColumns = [
    {
        id: 1,
        title: 'Personalized Learning Experience',
        icon: iconWhyUs1,
        description: 'Easily customize pages with images, text, and prompts to fit subjects like math, language, or science. Choose templates for different ages and enjoy engaging visuals that keep kids interested.\n',

    },
    {
        id: 2,
        title: 'Data Privacy and Security',
        icon: iconWhyUs2,
        description: 'Your data is protected by strong security measures, including data encryption at rest (AES-256) and in transit (TLS 1.2+). Our platform also adheres to key privacy regulations like GDPR.',

    },
    {
        id: 3,
        title: 'Encourages Creativity and Learning',
        icon: iconWhyUs3,
        description: 'Kids can color, doodle, and learn at the same time. Use printed pages for hands-on fun or digital versions for online learning.',
    }
];

const howItWorksSteps = [
    {
        id: 1,
        title: 'Register and Explore Free Coloring Pages',
        icon: iconStep1,
        description: 'Sign up on our platform to get started. Instantly access a collection of free coloring pages designed to inspire creativity and engage young minds in fun learning activities.'
    },
    {
        id: 2,
        title: 'Purchase Generation Credits',
        icon: iconStep2,
        description: 'Once you’re ready to create custom content, purchase generation credits. These credits allow you to access advanced features, including personalized coloring pages generation tailored to specific themes and educational goals.'
    },
    {
        id: 3,
        title: 'Generate and Print Custom Coloring Pages',
        icon: iconStep3,
        description: 'Use your credits to design unique coloring pages. Customize each page with themes, prompts, and visuals, then print or download your creations to use in learning sessions, classroom activities, or at-home fun.'
    }
];

const LandingPage: React.FC = () => {
    const isLoggedIn = !isTokenExpired(getToken());
    return (
        <div>
            {/* Hero Section */}
            <section className="hero bg-light py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6 mb-4 mb-md-0 text-center text-md-start">
                            <h1 className="display-5">Create Custom Coloring Pages for Learning and Fun</h1>
                            <p className="lead">
                                Engage young minds with personalized coloring pages. Perfect for parents and educators
                                looking to inspire creativity and learning through customized activities.
                            </p>
                            {!isLoggedIn ? (
                                <a href="/register" className="btn btn-primary btn-lg mt-3">Start Creating Now</a>
                            ) : (<a href="/dashboard" className="btn btn-success btn-lg">Go to Dashboard</a>)}
                        </div>
                        <div className="col-12 col-md-6 text-center">
                            <img src={logo} alt="Logo" className="img-fluid" style={{maxWidth: '80%'}}/>
                        </div>
                    </div>
                </div>
            </section>

            {/* Why Us Section */}
            <section className="why-us py-5">
                <div className="container">
                    <h2 className="text-center mb-4">Why Us</h2>
                    <div className="row row g-4">
                        {whyUsColumns.map(col => (
                            <div key={col.id} className="col-12 col-md-4 text-center">
                                <div className="mb-3">
                                    <img src={col.icon} alt={col.title} className="img-fluid why-us-icon"/>
                                </div>
                                <h5 dangerouslySetInnerHTML={{__html: col.title}}/>
                                <p>{col.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* How It Works Section */}
            <section className="how-it-works bg-light py-5">
                <div className="container">
                    <h2 className="text-center mb-4">How It Works</h2>
                    <div className="row g-4">
                        {howItWorksSteps.map(step => (
                            <div key={step.id} className="col-12 col-md-4 text-center">
                                <div className="mb-3">
                                    <img src={step.icon} alt={step.title} className="img-fluid why-us-icon"/>
                                </div>
                                <h5>{step.title}</h5>
                                <p>{step.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Sign Up Section */}
            <section className="py-5">
                <div className="container">
                    {!isLoggedIn ? (
                        <>
                            <h2 className="text-center mb-4">Sign Up</h2>
                        </>
                    ) : (
                        <>
                            <h2 className="text-center mb-4">Create Now </h2>
                        </>
                    )}
                    {!isLoggedIn ? (
                        <>
                            <p className="text-center mb-4">
                                Join our platform today and unlock a world of personalized learning and creativity.
                                Whether you're an educator, parent, or simply passionate about learning, we offer tools
                                to make it engaging and fun!
                            </p>
                            <div className="text-center">
                                <a href="/register" className="btn btn-primary btn-lg">Get Started for Free</a>
                            </div>
                        </>
                    ) : (
                        <>
                            <p className="text-center mb-4">
                                Welcome back! Head over to your dashboard to continue creating and exploring.
                            </p>
                            <div className="text-center">
                                <a href="/dashboard" className="btn btn-success btn-lg">Go to Dashboard</a>
                            </div>
                        </>
                    )}
                </div>
            </section>

            {/* Footer */}
            <footer className="bg-dark text-light py-4">
                <div className="container">
                    <div className="row g-4">
                        <div className="col-12 col-md-4 text-center text-md-start">
                            <h5>About Us</h5>
                            <p>
                                Letz Code is dedicated to fostering creativity and learning through personalized
                                educational tools. Our mission is to make learning enjoyable and accessible.
                            </p>
                        </div>
                        <div className="col-12 col-md-4 text-center text-md-start">
                            <h5>Important Links</h5>
                            <ul className="list-unstyled">
                                <li><a href="/terms" className="text-light">Terms of Service</a></li>
                                <li><a href="/privacy" className="text-light">Privacy Policy</a></li>
                                <li><a href="/about" className="text-light">Company Information</a></li>
                                <li><a href="/contact" className="text-light">Contact Us</a></li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-4 text-center">
                            <img src={logo} alt="Logo" className="img-fluid mb-2" style={{maxWidth: '80px'}}/>
                            <p>&copy; {new Date().getFullYear()} Letz Code. All rights reserved.</p>
                            <div className="d-flex justify-content-center gap-2">
                                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"
                                   className="text-light">
                                    <i className="fab fa-facebook"></i>
                                </a>
                                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"
                                   className="text-light">
                                    <i className="fab fa-twitter"></i>
                                </a>
                                <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"
                                   className="text-light">
                                    <i className="fab fa-instagram"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default LandingPage;
import {clearToken, getToken, isTokenExpired} from './auth';

interface FetchOptions extends RequestInit {
    requiresAuth?: boolean;
}

export const fetchWithAuth = async (url: string, options: FetchOptions = {}): Promise<Response> => {
    const token = getToken();

    if (options.requiresAuth && token) {
        if (isTokenExpired(token)) {
            clearToken();
            window.location.href = '/login'; // Redirect to login if token expired
            return Promise.reject(new Error('Token expired'));
        }

        options.headers = {
            ...options.headers,
            'Authorization': `Bearer ${token}`,
        };
    }

    const response = await fetch(url, options);

    if (response.status === 401) {
        // Token is invalid or expired
        clearToken();
        //   window.location.href = '/login'; // Redirect to login on unauthorized access
        throw new Error('Unauthorized');
    }

    return response;
};

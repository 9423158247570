import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { fetchWithAuth } from "../utils/fetchClient";
import './BuyCredits.css';
import FAQ from "../components/FAQ";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || 'your-publishable-key');

const offers = [
    {
        id: '50_books',
        quantity: 50,
        price: 1480,
        description: '50 Coloring Pages',
        priceId: process.env.REACT_APP_STRIPE_50_BOOKS_PRICE || 'price_key'
    },
    {
        id: '150_books',
        quantity: 150,
        price: 3600,
        description: '150 Coloring Pages',
        priceId: process.env.REACT_APP_STRIPE_150_BOOKS_PRICE || 'price_key'
    },
    {
        id: '250_books',
        quantity: 250,
        price: 5800,
        description: '250 Coloring Pages',
        priceId: process.env.REACT_APP_STRIPE_250_BOOKS_PRICE || 'price_key'
    },
];

const BuyCredits: React.FC = () => {
    const stripe = useStripe();
    const [openFAQ, setOpenFAQ] = useState<string | null>(null);

    const handleSubmit = async (e: React.FormEvent, selectedPriceId: string) => {
        e.preventDefault();
        const response = await fetchWithAuth(`/api/create-checkout-session?priceId=${selectedPriceId}`, {
            method: 'POST',
            requiresAuth: true,
            headers: { 'Content-Type': 'application/json' }
        });

        const data = await response.json();
        window.location.href = data;
    }

    const toggleFAQ = (id: string) => {
        setOpenFAQ(openFAQ === id ? null : id);
    }

    return (
        <Elements stripe={stripePromise}>
            <div className="container py-5">
                <h2 className="text-center mb-4">Buy Color Books</h2>
                <div className="row">
                    {offers.map((offer) => (
                        <div className="col-md-4 mb-4" key={offer.id}>
                            <div className="card shadow-sm border-0 offer-card">
                                <div className="card-body text-center">
                                    <h5 className="card-title">{offer.description}</h5>
                                    <p className="card-price">€{(offer.price / 100).toFixed(2)}</p>
                                    <form onSubmit={(e) => handleSubmit(e, offer.priceId)}>
                                        <button type="submit" className="btn btn-primary w-100 fw-bold checkout-btn">
                                            Checkout
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="faq-section mt-5">
                    <h3 className="text-center mb-3">Frequently Asked Questions</h3>
                    <FAQ />
                </div>
            </div>
        </Elements>
    );
};

export default BuyCredits;
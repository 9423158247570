import { useNavigate } from 'react-router-dom';
import {clearToken} from "../utils/auth";

const useLogout = () => {
    const navigate = useNavigate();

    const logout = () => {
        // Clear authentication tokens or session data
        clearToken(); // Assuming you're using localStorage for token storage

        // Redirect to login or homepage
        navigate('/'); // Update with your login route or preferred redirection path
    };

    return logout;
};

export default useLogout;
import React, { useEffect, useState } from 'react';
import { fetchWithAuth } from "../utils/fetchClient";
import './UserPage.css';


const UserPage: React.FC = () => {
    const [user, setUser] = useState<any | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchUserData = async () => {
        try {
            const response = await fetchWithAuth('/api/user/me', {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                requiresAuth: true
            });
            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }
            const data = await response.json();
            setUser(data);
            setError(null);
        } catch (err) {
            setError((err as Error).message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    return (
        <section className="container py-5 user-profile-container">
            <h2 className="text-center mb-4 ">My Profile</h2>

            {loading && <p className="text-center text-muted">Loading my data...</p>}
            {error && <p className="text-danger text-center">Error: {error}</p>}

            {user && (
                <div className="card shadow-sm border-0 user-card">
                    <div className="card-body">
                        <p className="card-text">
                            <strong>Email:</strong> {user.email}
                        </p>
                        <p className="card-text">
                            <strong>Credits:</strong> {user.remainingAmountOfCredits}
                        </p>
                    </div>
                </div>
            )}
        </section>
    );
};

export default UserPage;
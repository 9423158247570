import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {getToken} from "../utils/auth";

interface ProtectedRouteProps {
    redirectTo?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({redirectTo = '/login'}) => {
    const token = getToken();

    return token !== null ? <Outlet/> : <Navigate to={redirectTo} replace/>;
};

export default ProtectedRoute;